.bFooter {
  margin: 8rem 0;
  background-image: linear-gradient(rgba(0, 0, 0, .55), rgba(0, 0, 0, .52)), url('../../Assets/aecc6c136727009.6200343a9b2ac.gif');
  width: 100%;
  background-position: center;
  background-size: contain;
  border-radius: 1.2rem;
  padding: 5rem 0;
}

.cusImg {
  width: 3.8rem;
  border: 3px solid aliceblue;
  border-radius: 50%;
  margin-left: -1rem;
  object-fit: cover;
  aspect-ratio: 2/2;
  object-position: center;
  margin-left: -1rem;
}

.imagesArticle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  width: 40%;
  margin: auto;
}

.arrowAndText p {
  color: #f5f7fb;
  font-family: 'Archivo', sans-serif;
}

.arrowAndText p span {
  font-style: italic;
  color: #d4145a;
}

.why {
  text-align: center;
  font-weight: 700;
  font-size: 2.8rem;
  color: #d4145a;
}

.whyText {
  font-size: 1.2rem;
  text-align: center;
  width: 80%;
  margin: 1rem auto 2rem;
  line-height: 1.5;
  font-family: 'Archivo', sans-serif;
  color: #f5f7fb;
}

.emailDivDown {
  max-width: 65%;
  margin: 2rem auto 1rem;
  display: flex;
  justify-content: center;
  gap: 1.2rem;
}

.emailDivDown input {
  width: 45%;
  border-radius: 0.6rem;
  border: 2px solid #d4145a;
  padding: 0.6rem;
  font-size: 1.1rem;
}

.emailDivDown button {
  border-radius: 0.6rem;
  border: transparent;
  background-color: #d4145a;
  font-size: 1.1rem;
  padding: 0.8rem 0.5rem;
  font-family: 'Archivo', sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: aliceblue;
  font-style: normal;
}

@media screen and (max-width: 1042px) {
  @media screen and (max-width: 800px) {
    .bFooter {
      margin: 3rem 0;
      padding: 3rem 0;
    }

    .emailDivDown {
      max-width: 80%;
    }

    .imagesArticle {
      gap: 1rem;
      width: 55%;
      margin: auto;
    }

    @media screen and (max-width: 600px) {
      .bFooter {
        margin: 5rem 0 3rem;
      }

      .whyText {
        width: 95%;
        font-size: 1rem;
      }

      .why {
        font-size: 2.3rem;
      }

      .arrowAndText {
        width: 55%;
      }

      .cusImg {
        width: 3.2rem;
        margin-left: -0.8rem;

      }

      .imagesArticle {
        width: 90%;
      }

      .emailDivDown {
        max-width: 85%;
        flex-direction: column;
      }

      .emailDivDown input {
        width: 100%;
      }
    }
  }
}