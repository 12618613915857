.profileSection {
  display: grid;
  margin: auto;
  max-width: 1280px;
  align-items: start;
  grid-template-columns: repeat(4, 1fr);
}

.coverImgDiv {
  grid-column: 1/5;
  align-self: center;
}

.profileSection .userInfoContainer {
  position: sticky;
  top: 10rem;
  display: inline-block;
  min-height: 20vh;
  z-index: 99;
}

.userInfoDiv {
  grid-column: 1;
  border-radius: 1.2rem;
  height: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 20rem;
}

.wishItemsDiv {
  grid-column: 2/5;
  margin: 1rem auto;
  width: 98%;
  min-height: 40vh;
}

.wishBtns {
  justify-content: space-between;
  display: flex;
}

.userCover {
  width: 100%;
  height: 18rem;
  object-fit: cover;
  border-bottom-right-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem;
}

.userImg {
  width: 8rem;
  border-radius: 50%;
  aspect-ratio: 2/2;
  object-fit: cover;
  position: absolute;
  top: -5rem;
  left: 2rem;
  border: 5px solid #f5f8f8;
}

.userBio {
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Archivo', sans-serif;
  margin: 1rem auto;
  width: 70%;
  line-height: 1.3;
}

.userNameDiv {
  margin: 4rem auto 1rem;
  width: 70%;
}

.userNameDiv p {
  font-size: .8rem;
  font-family: 'Archivo', sans-serif;
  margin: .3rem auto;
}

.UserSocialIcons {
  width: 1.6rem;
}

.wishItemBtn {
  font-size: 1rem;
  font-family: 'Archivo', sans-serif;
  margin-right: 1rem;
  padding: .3rem .5rem;
  border-radius: .642rem;
  border: 2px solid #d4145a;
  color: #d4145a;
  background-color: transparent;
  align-self: center;
  cursor: pointer;
}

.orderbyIcon {
  font-size: 1.5rem;
  color: #d4145a;
  cursor: pointer;
}

.leftBtns,
.rightBtns,
.EditIconsDiv {
  display: flex;
  align-items: center;
}

._filters_btn {
  background-color: transparent;
  border: transparent;
  cursor: pointer;
}

.leftBtns {
  position: relative;
}

.rightBtns {
  gap: 1.2rem;
}

.EditIconsDiv {
  width: 80%;
  flex-direction: column;
  gap: 1rem;
}

.profileEdit,
.editIconsBtn {
  font-size: 1.1rem;
  color: #1547d2;
  padding: .3rem .6rem;
  border: transparent;
  border-radius: .8rem;
  font-weight: 500;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: .3rem;
  cursor: pointer;
  font-family: 'Archivo', sans-serif;
}

#addWish {
  background-color: #d4145a;
  color: #f5f8f8;
  border: transparent;
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: .3rem;
  padding: .5rem .8rem;
}

.theWishesSection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(223.2px, 1fr));
  gap: 1rem;
  row-gap: 2rem;
  column-gap: 1rem;
  margin-top: 3rem;
}

/* This is the update profile section */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.editInfoSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #fbfdfd;
  flex-direction: column;
  width: 600px;
  margin: 0rem auto;
  position: fixed;
  left: 50%;
  top: 45%;
  backdrop-filter: blur(5rem);
  transform: translate(-50%, -50%);
  padding: 1.6rem 1.8rem;
  border-radius: 1.42rem;
  border: 1px solid #cfcfcf;
  overflow-y: scroll;
  justify-content: space-around;
}

.dorpBackModel {
  background-color: #535353;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: .5;
}

body.modal-open {
  overflow: hidden;
}

body.modal-open .dorpBackModel {
  pointer-events: auto;
}

.coverImgEditDiv {
  width: 100%;
  grid-column: 1/5;
}

.editCoverImg {
  width: 100%;
  border-radius: .8rem;
  height: 10rem;
  object-fit: cover;
}

.editUserImg {
  width: 6.42rem;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 2/2;
}

.LabelsDiv {
  display: flex;
  grid-column: 1/5;
  width: 100%;
  align-items: center;
  gap: 1rem;
  margin: 1.6rem 0;
}

.LabelsDiv1 {
  position: relative;
  cursor: pointer;
}

.LabelsDiv2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.LabelsDiv div label,
.userBioInput {
  display: flex;
  flex-direction: column;
  grid-column: 1/5;
  gap: .6rem;
  width: 100%;
}

.LabelsDiv div label input {
  height: 2rem;
  width: 100%;
  font-size: .8rem;
  padding: .3rem .5rem;
  border-radius: .5rem;
  border: 1px solid #1547d2;
}

.userBioInput textarea {
  height: 10rem;
  border-radius: 1rem;
  border: 1px solid #1547d2;
  padding: 1rem;
}

.updateProfileBtn {
  font-size: 1.42rem;
  grid-column: 1/5;
  width: 100%;
  padding: .5rem .2rem;
  border-radius: .8rem;
  border: transparent;
  background-color: #1547d2;
  color: #fbfdfd;
  margin: 2rem 0 0;
  cursor: pointer;
}

.editProfileClose {
  font-size: 1.42rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.profileLinks {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2.42rem;
  background-color: #c2c0c0be;
  padding: .2rem .5rem;
  border-radius: .5rem;
  cursor: pointer;
}

.userSocialLinks {
  display: flex;
  gap: .8rem;
  flex-wrap: wrap;
  margin: 1.5rem auto 3rem;
  width: 70%;
}

.profileLinks img {
  width: 30px;
}

.editInfoSection h1 {
  font-size: 1.5rem;
  margin: 1rem 0;
  grid-column: 1/5;
  color: #d4145a;
}

.editProfileImg {
  width: 6.42rem;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 2/2;
}

.ProfilePlus {
  width: 4.2rem;
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.CoverPlus {
  width: 5.2rem;
  position: absolute;
  left: 50%;
  top: 18%;
  transform: translate(-50%);
}

.UpdateProfileLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.userBioInput p {
  color: #d4145aa6;
  font-size: 1.2rem;
}

._payment_setup_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem auto;
  width: 80%;
  gap: .8rem;
  background-color: #eaeaea;
  padding: 1rem;
  border-radius: .5rem;
}

._payment_setup_p {
  line-height: 1.3;
  letter-spacing: .42px;
  font-size: 1rem;
}

._payment_setup_btn {
  font-size: 1rem;
  padding: .8rem;
  border-radius: .5rem;
  border: transparent;
  background-color: #d4145a;
  color: #fbfdfd;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Archivo', sans-serif;
}

.surprise_btn {
  font-size: 1.2rem;
  color: aliceblue;
  padding: .4rem .5rem;
  background-color: #d4145a;
  border-radius: .6rem;
  font-weight: 600;
  border: transparent;
  display: flex;
  align-items: center;
  gap: .3rem;
  cursor: pointer;

  font-family: 'Archivo', sans-serif;
}

._not_connected_div {
  margin: 3rem auto 0;
  text-align: center;
  grid-column: 2/5;
  min-height: 30vh;
  width: 98%;
}

._not_connected_msg_title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #182C61;
  margin: 1rem 0;
}

._not_connected_msg_p {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(87, 87, 87);
  margin: 1rem 0;
}

._userNameErr,
#_invalidError {
  color: red;
  background-color: rgba(239, 209, 209, 0.693);
  padding: .2rem .4rem;
  border-radius: .3rem;
  font-size: .8rem;
  font-weight: 500;
  margin: .1rem 0;
}

#_invalidError {
  margin: .5rem 0;
  font-size: 1rem;
  padding: .3rem .5rem;
}

@media screen and (max-width: 1024px) {
  .theWishesSection {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }

  @media screen and (max-width: 900px) {
    .theWishesSection {
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    }

    ._not_connected_msg_title {
      font-size: .8rem;
      margin: 0;
    }

    ._not_connected_msg_p {
      font-size: .8rem;
      margin: .6rem;
    }

    @media screen and (max-width: 820px) {
      .theWishesSection {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      }

      ._payment_setup_div {
        width: 90%;
      }

      ._payment_setup_p {
        font-size: .8rem;
      }

      ._payment_setup_btn {
        font-size: .8rem;
        padding: 0.5rem;
        border-radius: 0.4rem;
        font-weight: 500;
      }

      @media screen and (max-width: 796px) {
        .theWishesSection {
          grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        }

        .userImg {
          width: 6.5rem;
          top: -3.6rem;
          left: 1rem;
        }

        @media screen and (max-width: 600px) {
          .theWishesSection {
            grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
          }

          .userCover {
            height: 8rem;
          }

          .userImg {
            width: 5.5rem;
          }

          .userNameDiv {
            margin: 2rem 0 1rem;
            width: 75%;
          }

          .userNameDiv h3 {
            font-size: 1rem;
          }

          .profileSection {
            grid-template-columns: repeat(4, 1fr);
          }

          .wishItemsDiv {
            grid-column: 1/5;
            margin: 1rem auto;
            width: 98%;
          }

          .userInfoDiv {
            grid-column: 1/5;
          }

          .userBio {
            margin: 1rem 0;
          }

          .EditIconsDiv {
            width: 100%;
            flex-direction: row;
          }

          .profileEdit,
          .editIconsBtn {
            font-size: 1rem;
            border-radius: .5rem;
          }

          .profileLinks {
            width: 40%;
            gap: 1rem;
          }

          .profileSection .userInfoContainer {
            position: relative;
            top: 0;
            padding: 0.5rem 0.8rem;
            min-height: 5rem;
          }

          .wishItemsDiv {
            margin: 1.3rem auto;
          }

          #addWish {
            font-size: 1rem;
            margin-right: 0;
          }

          .theWishesSection {
            row-gap: 1.5rem;
            column-gap: 1rem;
            margin-top: 1.5rem;
          }

          .userSocialLinks {
            width: 100%;
          }

          .profileLinks p {
            font-size: 1rem;
          }

          .profileLinks img {
            width: 1.5rem;
          }

          .editInfoSection {
            width: 330px;
            padding: 1.5rem 1rem;
            top: 50%;
          }

          .LabelsDiv {
            flex-direction: column;
            margin: .8rem 0 1.4rem;
            gap: 0rem;
          }

          .userBioInput textarea {
            height: 7.5rem;
          }

          .coverImgEditDiv {
            height: 6rem;
          }

          .editUserImg {
            width: 6rem;
            position: absolute;
            top: -4rem;
            left: 50%;
            transform: translate(-50%);
          }

          .LabelsDiv2 {
            margin: .2rem 0 0;
          }

          .editCoverImg {
            height: 8rem;
          }

          .CoverPlus {
            width: 3.3rem;
          }

          .ProfilePlus {
            width: 3.2rem;
            left: 1.5rem;
            top: 1.5rem;
          }

          .editInfoSection h1 {
            font-size: 1.2rem;
          }

          .userBioInput p {
            font-size: .8rem;
          }

          ._payment_setup_div {
            margin: 0;
            align-items: flex-start;
          }

          .surprise_btn {
            font-size: 1rem;
            padding: 0.2rem 0.4rem;
            border-radius: 0.4rem;
            font-weight: 500;
          }

          ._not_connected_div {
            margin: 1rem auto 0;
            grid-column: 1/5;
            width: 90%;
            text-align: left;
            min-height: 5rem;
          }

          #_invalidError {
            margin: .3rem 0;
            font-size: .8rem;
            padding: .2rem .4rem;
          }

          @media screen and (max-width: 374px) {
            .theWishesSection {
              grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
            }
          }
        }
      }
    }
  }
}