.wish_details {
  display: flex;
  flex-direction: column;
  margin: 1.3rem .3rem;
  gap: .52rem;
}

.surprise_btn {
  font-size: 1.2rem;
  color: aliceblue;
  padding: .4rem .5rem;
  background-color: #d4145a;
  border-radius: .6rem;
  font-weight: 600;
  border: transparent;
  display: flex;
  align-items: center;
  gap: .3rem;
  cursor: pointer;

  font-family: 'Archivo', sans-serif;
}

.localBackdrop {
  background-color: #5353537a;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: .5;
  z-index: 100;
}

._surprise_inputs_div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.3rem 0;
  width: 80%;
}

._surprise_inputs_div label {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  color: #7f7e7e;
  /* width: 100%; */
}

._surprise_inputs_div input {
  padding: .5rem .8rem;
  border-radius: .5rem;
  border: 1px solid #d4145a;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  width: 100%;
  font-family: 'Archivo', sans-serif;
}

._surprise_btn {
  padding: .8rem 1rem;
  border-radius: .5rem;
  border: transparent;
  font-size: 1rem;
  font-weight: 600;
  color: aliceblue;
  background-color: #d4145a;
  cursor: pointer;
}

._surprise_btn:hover {
  background-color: #1547d2;
  color: aliceblue;
}

._surprise_amount_error {
  color: red;
  font-size: .8rem;
  font-weight: 500;
  font-family: 'Archivo', sans-serif;
}

#SurpriseIcon_222_16960 {
  display: flex;
  align-items: center;
  justify-content: center;

}

.wishPopUp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(244, 247, 250);
  width: 25rem;
  border-radius: .8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: 3.8rem 1.5rem 1.5rem 1.5rem;
  z-index: 100;
}

.popUpBtns {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 1.5rem auto;
  gap: 1rem;
}

.popUpBtns button {
  padding: .8rem 1rem;
  border-radius: .5rem;
  border: transparent;
  font-size: 1rem;
  font-weight: 600;
  color: aliceblue;
  background-color: #d4145a;
  cursor: pointer;
}

.popUpBtns button:nth-child(2) {
  background-color: transparent;
  color: #d4145a;
  border: 1px solid #d4145a;
}

._popUpImg {
  width: 235px;
  height: 250px;
  object-fit: cover;
  border-radius: .8rem;
  margin-bottom: 1rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

._popUpCloseIcon {
  position: absolute;
  top: 1.5rem;
  right: 1.6rem;
  transform: translate(50%, -50%);
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.3rem;
}

._popUpPrice {
  font-size: 1rem;
  letter-spacing: .6px;
  font-weight: 500;
  color: rgb(11, 11, 11);
  font-family: sans-serif;
}

._popUpWishName {
  font-size: 1.1rem;
  font-weight: 500;
  color: #464545;
}

@media screen and (max-width: 1042px) {
  @media screen and (max-width: 800px) {
    .wishPopUp {
      width: 23rem;
    }

    @media screen and (max-width: 600px) {
      .surprise_btn {
        font-size: 1rem;
        padding: .2rem .3rem;
        border-radius: .4rem;
        font-weight: 600;
        gap: .3rem;
      }

      .wishPopUp {
        width: 20rem;
        padding: 2.8rem 1rem 1rem 1rem;
      }

      .popUpBtns button {
        padding: .5rem .8rem;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}