._cart_container {
  max-width: 100%;
  min-height: 60vh;
  width: 1280px;
  margin: 5rem auto;
}

._cart_container h2 {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  color: #121212;
  padding-left: 1rem;
}

._empty_cart {
  text-align: center;
  font-size: 1.2rem;
  margin: 1rem 0;
}

._cart_titles {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  margin: 0 0 1rem;
  padding: 0 0 1rem 1rem;
  text-align: left;
  margin: 1rem 0;
}

._cart_titles h5 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #464646;
  letter-spacing: .5px;
}

._cart_titles p {
  font-size: 1rem;
  font-weight: 400;
  color: #464646;
  letter-spacing: .5px;
}

._cart_wish_sections {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 3fr repeat(2, 1fr);
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}

._cart_wish_sections>p {
  font-size: 1.3rem;
  font-weight: 600;
  color: #d4145ac7;
  letter-spacing: .5px;
}

._cart_wish_sections>p:nth-child(3) {
  text-align: center;
}

._cart_item {
  display: grid;
  grid-template-columns: 3fr repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  padding: .6rem;
  border-bottom: 1px solid #ccc;
}

._cart_wish_img {
  width: 120px;
  height: 140px;
  object-fit: cover;
  grid-column: 1/2;
  border-radius: .3rem;
}

._wish_rapper {
  display: flex;
  gap: 1rem;
}

._cart_qnty_btns {
  display: flex;
  gap: 1rem;
}

._cart_wish_details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

._cart_wish_details ._cart_wish_price {
  font-size: 1rem;
  margin: .5rem 0;
  letter-spacing: .5px;
}

._cart_remove_btn {
  width: 6.3rem;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: .3rem .1rem;
  border-radius: .42rem;
  background: #464646ae;
  color: #fff;
  border: transparent;
}

._cart_remove_btn:hover,
._clear_cart_btn:hover {
  background: #d4145aae;
  color: #fff;
  border: transparent;
}

._cart_qnty_btn {
  background: transparent;
  border: 1px solid #1547d2;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #1547d2;
  padding: .06rem .1rem;
  border-radius: .2rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

._cart_subtotal {
  font-size: 1.1rem;
  font-weight: 500;
  color: #252525;
  letter-spacing: .5px;
  font-family: sans-serif;
}

._cart_bottom_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 3rem;
}

._clear_cart_btn {
  background: transparent;
  border: 1px solid #464646ae;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #464646ae;
  padding: .5rem .8rem;
  border-radius: .42rem;
}

._cart_total_details {
  display: flex;
  flex-direction: column;
  gap: .8rem;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}

._cart_total_details h5 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #252525;
  letter-spacing: .5px;
}

._add_more_wishes_btn {
  background: transparent;
  border: transparent;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #1547d2;
  padding: .5rem .8rem;
  border-radius: .42rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

._add_more_wishes_btn:hover {
  background: #1547d2;
  color: #fff;
  border: transparent;
}

._cart_fan_info {
  display: flex;
  flex-direction: column;
  gap: .8rem;
  align-items: flex-start;
  justify-content: center;
}

._cart_message {
  width: 80%;
  border-radius: .6rem;
  border: 1px solid #ccc;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.3;
  outline: none;
  resize: none;
  height: 10rem;
}

._msg_div ._add_message {
  font-size: 1.3rem;
  color: #252525;
  margin-left: .6rem;
}

._cart_inputs_div {
  display: flex;
  gap: .8rem;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
}

/* Forms styles */
/* ------------------------------------------------------------------------------- */

.form__div {
  position: relative;
  height: 3.25rem;
  width: 40%;
  background-color: #fff;
  border-radius: .5rem;
}

.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  outline: none;
  padding: 1rem;
  background: none;
  z-index: 1;
}

.form__label {
  position: absolute;
  left: 1rem;
  top: 1rem;
  padding: 0 0.25rem;
  background-color: #fff;
  color: #525252;
  font-size: 1rem;
  transition: 0.3s;
}

/*Input focus move up label*/
.form__input:focus+.form__label {
  top: -0.5rem;
  left: 0.8rem;
  color: #1547d2;
  font-size: 1rem;
  font-weight: 500;
  z-index: 10;
}

/*Input focus sticky top label*/
.form__input:not(:placeholder-shown).form__input:not(:focus)+.form__label {
  top: -0.5rem;
  left: 0.8rem;
  z-index: 10;
  font-size: 1rem;
  font-weight: 500;
}

/*Input focus*/
.form__input:focus {
  border: 1px solid #1547d2;
}

._fan_email_notice,
._fan_name_notice {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #d4145a;
  font-size: 1.3rem;
  cursor: pointer;
}

._email_notice_msg,
._name_notice_msg {
  position: absolute;
  top: 3.6rem;
  right: -5rem;
  width: 80%;
  color: #fff;
  font-size: .65rem;
  line-height: 1.3;
  background-color: #464646;
  padding: .5rem;
  border-radius: .4rem;
  letter-spacing: .5px;
  visibility: hidden;
  z-index: 10;
}

._name_notice_msg {
  width: auto;
  right: -1rem;
}

.form__div:hover ._email_notice_msg,
.form__div:hover ._name_notice_msg {
  visibility: visible;
}

/* ------------------------------------------------------------------------------------ */

._cart_terms_policy,
._cart_publish_btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  border: transparent;
  color: #121212;
  font-size: 1.5rem;
  background: transparent;
  margin: 2rem 0 .6rem;
  font-family: 'Archivo', sans-serif;
}

._cart_publish_checkbox,
._cart_terms_policy_checkbox {
  cursor: pointer;
}

._cart_terms_div {
  width: 90%;
}

._cart_terms {
  margin-left: 1.5rem;
}

._cart_terms_div ._cart_terms li,
._publishing_notice {
  font-size: 1rem;
  font-weight: 400;
  color: #777777;
  line-height: 1.3;
  margin: .5rem 0;
  letter-spacing: .5px;
}

._checkout_btn {
  background: #1547d2;
  width: 10rem;
  border: transparent;
  outline: none;
  cursor: pointer;
  font-size: 1.3rem;
  color: #fff;
  padding: .6rem .8rem;
  border-radius: .42rem;
  margin: 1rem 0;
  font-weight: 600;
}

._checkout_btn_div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 97%;
  margin-top: 2.3rem;
}

._cart_no {
  font-weight: 600;
  color: #1547d2;
  font-family: 'Source Serif 4', serif;
  font-style: italic;
}

._cart_username {
  font-weight: 400;
  color: #1547d2;
  font-family: serif;
  font-style: italic;
}

._terms_policy_span {
  color: #d4145a;
  cursor: pointer;
  font-style: italic;
}

._msg_div {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: flex-start;
  justify-content: center;
  margin: .6rem 0;
  width: 80%;
}

._msg_div ._cart_message_notice {
  font-size: .8rem;
  font-weight: 400;
  color: #777777;
  line-height: 1.3;
  letter-spacing: .5px;
  margin-left: .6rem;
}

._msg_div ._msg_count {
  color: #121212;
}

@media screen and (max-width: 1024px) {
  @media screen and (max-width: 800px) {

    ._cart_terms_policy,
    ._cart_publish_btn {
      font-size: 1.1rem;
    }

    ._cart_terms_div ._cart_terms li,
    ._publishing_notice {
      font-size: .8rem;
    }

    ._cart_message {
      width: 90%;
    }

    ._msg_div {
      width: 100%;
    }

    ._cart_inputs_div {
      width: 100%;
    }

    .form__div {
      height: 2.8rem;
    }

    .form__label {
      font-size: .8rem;
    }

    ._fan_email_notice,
    ._fan_name_notice {
      font-size: 1rem;
    }

    ._cart_wish_sections>p {
      font-size: 1rem;
      font-weight: 500;
    }

    ._cart_total_details h5 {
      font-size: 1rem;
    }

    ._cart_total_details {
      gap: .6rem;
    }

    ._add_more_wishes_btn {
      font-size: 1rem;
      gap: .3rem;
    }

    ._clear_cart_btn {
      font-size: 1rem;
      padding: .3rem .5rem;
    }

    ._cart_remove_btn {
      width: 5.6rem;
      font-size: 1rem;
    }

    ._cart_container h2 {
      font-size: 1.6rem;
    }

    ._cart_titles h5 {
      font-size: 1rem;
    }

    ._cart_titles p {
      font-size: .8rem;
    }

    ._cart_titles {
      margin: .6rem;
      text-align: left;
      padding: 0;
    }

    ._checkout_btn {
      width: auto;
      font-size: 1rem;
      margin: .5rem 0;
    }

    @media screen and (max-width: 600px) {
      ._cart_container {
        margin: 2rem auto;
      }

      ._cart_wish_sections {
        padding: .5rem;
        gap: .8rem;
      }

      ._cart_container h2 {
        font-size: 1.5rem;
        margin: 0 .5rem 1rem;
        padding: 0;
      }

      ._wish_rapper {
        gap: .5rem;
      }

      ._cart_wish_img {
        width: 80px;
        height: 90px;
      }

      ._cart_wish_details ._cart_wish_price {
        font-size: .7rem;
        font-weight: 400;
        margin: .3rem 0;
      }

      ._cart_remove_btn {
        width: 3.8rem;
        font-size: .8rem;
        border-radius: 0.25rem;
      }

      ._cart_item {
        gap: .8rem;
      }

      ._cart_qnty_btns {
        gap: .5rem;
        justify-content: center;
      }

      ._cart_qnty_btn {
        font-size: .8rem;
        padding: .06rem .1rem;
      }

      ._cart_subtotal {
        font-size: 1rem;
        font-weight: 400;
      }

      ._cart_wish_name {
        font-size: .8rem;
        letter-spacing: .3px;
        line-height: 1.2;
      }

      ._cart_bottom_details {
        margin: 1rem .3rem .6rem;
      }

      ._cart_message {
        width: 100%;
      }

      ._cart_inputs_div {
        flex-direction: column;
      }

      .form__div {
        width: 100%;
      }

      ._cart_fan_info {
        margin: 0 .3rem;
      }

      ._add_more_wishes_btn {
        font-size: .8rem;
        font-weight: 600;
        letter-spacing: .3px;
      }

      ._msg_div ._add_message {
        font-size: 1rem;
      }

      ._cart_terms_policy,
      ._cart_publish_btn {
        font-size: 1rem;
      }

      ._cart_terms_div {
        width: 100%;
        padding: .5rem;
      }

      ._checkout_btn_div {
        margin-top: .3rem;
      }

      ._checkout_btn {
        font-size: 1rem;
        margin: .3rem 0;
        font-weight: 500;
        letter-spacing: .3px;
      }
    }
  }
}