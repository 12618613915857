.mainGrid {
  margin: 6rem 0;
  display: grid;
  gap: 1.2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto, 1fr);
}

.wishListIcon {
  width: 10rem;
}

.iWishDiv {
  position: relative;
}

.iWish {
  position: absolute;
  font-size: 3rem;
  bottom: -1rem;
  left: 2rem;
  color: #d4145a;
}

.LogoArt,
.supArt,
.cashArt {
  text-align: center;
}

.wishListArt {
  grid-column: 2/4;
  grid-row: 3;
  display: flex;
  gap: .8rem;
  align-items: center;
  background-color: #FBFFFE;
  border-radius: 1rem;
  padding: .5rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.LogoArt h3 {
  font-size: 2.8rem;
  color: #182C61;
  font-family: 'Avenue', sans-serif;
  font-weight: 600;
}

.LogoArt h3 span {
  font-family: 'Source Serif 4', serif;
  font-style: italic;
  color: #d4145a;
  font-weight: 500;
}

.cashArt {
  grid-column: 1;
  grid-row: 1 / 2;
  padding: 0 .8rem;
}

.supArt {
  grid-column: 2;
  grid-row: 1;
  padding: 0 .5rem;
}

.cashAndSup {
  font-size: 3.8rem;
}

.mainGrid article div h4 {
  font-family: 'Avenue', sans-serif;
  font-size: 1.8rem;
  color: #2d2f32f5;
}

.mainGrid article div h4 span {
  font-family: 'Source Serif 4', serif;
  font-style: italic;
  font-weight: 500;
}

.mainGrid article div p {
  font-size: 1.1rem;
  font-family: 'Avenue', sans-serif;
  color: rgb(87, 87, 87);
  margin: .6rem 0;
  line-height: 1.3;
}

.thankArt {
  grid-column: 1;
  grid-row: 2 / 4;
}

.profileArt {
  grid-column: 3;
  grid-row: 1 / 3;
}

.thankArt,
.profileArt {
  gap: 1rem;
  padding: 1rem 1rem;
}

.thankArt,
.profileArt,
.supArt,
.cashArt,
.LogoArt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FBFFFE;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.profileCus {
  width: 55%;
  border: 1px solid #182C61;
  border-radius: .6rem;
}

.thankImg {
  width: 90%;
  border: 2px solid rgb(209, 208, 208);
  border-radius: .5rem;
}

.cusImgDiv {
  display: flex;
}

@media screen and (max-width: 1042px) {
  @media screen and (max-width: 800px) {
    .LogoArt h3 {
      font-size: 2.2rem;
    }

    .mainGrid article div h4 {
      font-size: 1.6rem;
    }

    .mainGrid {
      margin: 3.6rem 0;
    }

    @media screen and (max-width: 600px) {
      .mainGrid {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        margin: 3rem 0;
      }

      .mainGrid article div h4 {
        font-size: 1.6rem;
      }

      .wishListIcon {
        width: 5rem;
      }

      .LogoArt {
        padding: 1rem 1.2rem;
      }

      .iWish {
        font-size: 2.3rem;
      }
    }
  }
}