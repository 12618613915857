.AddingLinksModule {
  display: flex;
  background-color: #fbfdfd;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  margin: 1rem auto;
  min-height: 20rem;
  height: auto;
  position: fixed;
  top: 42%;
  left: 50%;
  backdrop-filter: blur(5rem);
  transform: translate(-50%, -50%);
  padding: 2rem 3rem;
  border-radius: 1.42rem;
  border: 1px solid #cfcfcf;
  overflow-y: scroll;
  max-height: 35rem;
}

.closeLinksModule {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #1547d2;
  cursor: pointer;
}

.saveLinks {
  margin-top: 2rem;
  width: 100%;
  min-height: 3.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #1547d2;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
}

.addLinksBtn {
  margin: 1rem auto 0;
  min-height: 2rem;
  width: 42%;
  padding: 0 1rem;
  border-radius: 0.5rem;
  border: 2px solid #1547d2;
  background-color: transparent;
  color: #1547d2;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.linksPlusIcon {
  align-self: center;
  font-size: .8rem;
}

.linksLabel {
  width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  gap: 1rem;
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 2rem 1rem;
  transform: translate(-50%, -50%);
  background-color: #eff7f5;
}

#addModuleId {
  font-size: 1.2rem;
  background-color: #d4145a;
  color: #fbfdfd;
  border: transparent;
  padding: .2rem 1rem;
  border-radius: .6rem;
  width: 30%;
  cursor: pointer;
}

.linkInput,
.platformsSelection {
  width: 100%;
  height: 2rem;
  border-radius: .42rem;
  border: 1px solid #1547d2;
  margin: .5rem auto;
  padding: .5rem;
}

.UpdateLinks {
  margin: 2rem 0;
}

/* Displaying the icons css section */
/* ----------------------------------------------------------------------------------------------------- */

.socialMediaLinkDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  margin: .5rem 0;
}

.socialMediaLinkDiv div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.editLinksIcons {
  font-size: 1.3rem;
  cursor: pointer;
}

.platformNameDisplaying {
  padding: .5rem;
  font-size: 1.1rem;
  color: #9e1313;
  font-weight: 500;
}

body.modal-open {
  overflow: hidden;
}

body.modal-open .SocialLinksModule {
  pointer-events: auto;
}

.SocialLinksModule {
  background-color: #535353;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: .5;
}

.errMessage {
  color: red;
  font-size: 1rem;
  background-color: rgba(239, 209, 209, 0.693);
  padding: .5rem .3rem;
  border-radius: .42rem;
}

@media screen and (max-width: 1024px) {


  @media screen and (max-width: 800px) {


    @media screen and (max-width: 600px) {
      .AddingLinksModule {
        width: 310px;
        padding: .8rem 1.3rem;
      }

      .closeLinksModule {
        font-size: 1.3rem;
      }

      .UpdateLinks {
        font-size: 1rem;
      }

      .addLinksBtn {
        width: 52%;
        font-weight: 500;
      }

      .saveLinks {
        min-height: 3rem;
      }
    }
  }
}