.middleTextDiv {
  text-align: center;
  width: 80%;
  margin: auto;
}

.middleTextDiv h3 {
  font-size: 2.8rem;
  font-family: 'Archivo', sans-serif;
  color: #182C61;
  font-weight: 700;
}

.middleTextDiv h3 span {
  font-family: 'Source Serif 4', serif;
  font-weight: 700;
  font-size: 2.4rem;
}

.middleTextDiv p {
  font-family: 'Archivo', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 1.4rem 0;
  color: rgb(70, 70, 70);
}

.middleTextDiv p span {
  font-style: italic;
  color: #d4145a;
  font-family: 'Source Serif 4', serif;
}

@media screen and (max-width: 1042px) {
  @media screen and (max-width: 800px) {

    @media screen and (max-width: 600px) {
      .middleTextDiv {
        width: 99%;
      }

      .middleTextDiv h3 {
        font-size: 2rem;
        font-weight: 600;
      }

      .middleTextDiv h3 span {
        font-weight: 500;
        font-size: 1.6rem;
      }

      .middleTextDiv p {
        font-size: 1.1rem;
        line-height: 1.5;
        margin: 1rem 0;
      }
    }
  }
}