.login {
  margin: 8rem auto;
}

.signup {
  margin: 6.4rem auto;
}

.login,
.signup {
  width: 65%;
  display: flex;
  align-items: self-start;
  justify-content: flex-start;
  gap: 5rem;
}

.signSection {
  display: flex;
  margin: 1rem auto 2rem;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.signUpTitle {
  font-size: 3rem;
  font-family: 'Archivo', sans-serif;
}

.signUpCopy {
  font-size: 1.2rem;
  width: 75%;
  margin: 1rem auto;
  font-family: 'Archivo', sans-serif;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}

.signUpCopy span {
  font-style: italic;
  color: #182C61;
  font-family: 'Source Serif 4', serif;
}

.FormsDiv {
  width: 75%;
  margin: auto;
}

.loginTitle_h3 {
  font-size: 2.6rem;
  font-family: 'Archivo', sans-serif;
}

.loginTitle {
  color: rgb(97, 97, 97);
  font-size: 1.2rem;
  font-family: 'Archivo', sans-serif;
  text-align: left;
  margin: .2rem 0;
}

.loginTitle span {
  color: #1547d2;
  cursor: pointer;
  font-style: italic;
}

.loginImg {
  width: 70%;
  align-self: center;
}

.forms {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.42rem;
}

.pwd_forms_input {
  height: 2rem;
  width: 100%;
  border-radius: .42rem;
  font-size: 1rem;
  border: transparent;
}

.pwd_forms_input:focus {
  outline: none;
}

.pwdDiv,
.forms_inputs {
  height: 2.6rem;
  width: 100%;
  border-radius: .42rem;
  padding: .5rem;
  font-size: 1rem;
  background-color: #fff;
  border: 1.3px solid #1547d2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pwdEye {
  font-size: 1rem;
  cursor: pointer;
  padding-right: .5rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.forms button {
  font-size: 1.6rem;
  font-family: 'Archivo', sans-serif;
  text-align: center;
  background-color: #1547d2;
  border: transparent;
  border-radius: .42rem;
  color: white;
  padding: .5rem .8rem;
  cursor: pointer;
}

.Login_icon_div,
.SignUp_icon_div {
  margin: 1.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  background-color: #fff;
  border-radius: .42rem;
  border: 1.3px solid #1547d2;
  cursor: pointer;
}

.forgetPwdLink {
  margin-top: 1rem;
  color: #d4145ad3;
  font-size: 1.1rem;
  cursor: pointer;
}

.Login_icon_div,
.SignUp_icon_div p {
  font-size: 1.2rem;
  font-family: 'Archivo', sans-serif;
  color: rgb(97, 97, 97);
}

.loginIcons {
  background-color: white;
  font-size: 2.5rem;
  padding: .3rem;
  cursor: pointer;
  border-radius: .3rem;
}

.or {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  margin: .6rem 0;
  align-items: center;
  column-gap: .3rem;
}

.or:before,
.or:after {
  content: '';
  height: 1.2px;
  display: block;
  background: #000;
}

.logText {
  font-family: 'Archivo', sans-serif;
  text-align: center;
  margin: 1.3rem 0;
  font-size: 1.2rem;
  color: rgb(97, 97, 97);
  line-height: 1.3;
}

.logText span {
  color: #1547d2;
  font-style: italic;
  cursor: pointer;
}

.agree {
  display: flex;
  margin: .5rem 0;
  align-items: center;
  gap: .42rem;
}

#agreeCheck {
  width: 1.2rem;
}

.agreeText {
  font-size: 1rem;
  color: #d4145ad3;
  line-height: 1.3;
}

.agreeText span {
  font-weight: 600;
  font-family: 'Source Serif 4', serif;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.termsErrMsg,
#pwdErrMsg,
.emailErrMsg,
.matchErrMsg,
.emptyFieldsErrMsg,
.serverErrMsg,
.nameErrMsg {
  color: red;
  font-size: 1rem;
  background-color: rgba(239, 209, 209, 0.693);
  padding: .5rem .3rem;
  border-radius: .42rem;
}

#pwdErrMsg {
  margin: 0;
}

.matchErrMsg {
  margin: 1rem 0;
}

._signUp_text,
._login_text {
  margin-bottom: 1.8rem;
}

@media screen and (max-width: 1042px) {
  @media screen and (max-width: 800px) {

    .login,
    .signup {
      width: 80%;
    }

    .loginImg {
      display: none;
    }

    @media screen and (max-width: 600px) {
      .signSection {
        display: flex;
        margin: 1rem auto;
      }

      .signUpTitle {
        font-size: 1.8rem;
      }

      .login,
      .signup {
        width: 100%;
        margin: 3rem auto 1rem;
      }

      .loginTitle_h3 {
        font-size: 1.8rem;
      }

      .loginTitle p {
        font-size: 1rem;
      }

      .or {
        font-weight: 500;
      }

      .forms input {
        height: 2.42rem;
      }

      /* .forms {
        margin: 1rem auto 2.2rem;
      } */

      .forms button {
        font-size: 1.42rem;
      }

      .signUpCopy {
        font-size: 1.1rem;
        width: 95%;
        line-height: 1.5;
      }

      .signUpTitle {
        font-size: 2rem;
      }

      .FormsDiv {
        width: 95%;
      }

      .logText {
        font-size: 1rem;
      }

      .agree {
        align-items: flex-start;
      }

      .agreeText span {
        font-weight: 500;
      }

      .spinnerImg {
        width: 5rem;
      }

      .Login_icon_div,
      .SignUp_icon_div {
        gap: 1.8rem;
        margin: 1.2rem 0;
      }

      /* .or:before {
        right: 10rem;
        margin-right: 0.6em;
      }

      .or:after {
        left: 10rem;
        margin-left: 0.6em;
      } */
    }
  }
}