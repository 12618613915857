.wishUploaderSection {
  display: flex;
  background-color: #fbfdfd;
  flex-direction: column;
  width: 400px;
  margin: 3rem auto;
  height: 45rem;
  position: fixed;
  left: 50%;
  backdrop-filter: blur(5rem);
  transform: translate(-50%, -50%);
  padding: 2rem 3rem;
  border-radius: 1.42rem;
  border: 1px solid #cfcfcf;
  overflow-y: scroll;
  z-index: 200;
}

.wishInfoInputsDiv,
.wishInfoInputsDiv label {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: .2rem;
}

.wishInfoInputsDiv {
  gap: 1.42rem;
}

.wishUploadImg {
  max-width: 250px;
  min-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.imgUploaderDiv {
  width: 98%;
  margin: 2rem auto 0;
  text-align: center;
}

.errorMsg {
  color: red;
  font-size: 1rem;
  background-color: rgba(239, 209, 209, 0.693);
  padding: .5rem .3rem;
  border-radius: .42rem;
  margin: 1rem 0;
}

body.modal-open {
  overflow: hidden;
}

body.modal-open .dropBack {
  pointer-events: auto;
}

.dropBack {
  background-color: #535353;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: .5;
  z-index: 100;
}

.wishInfoInputsDiv label input {
  height: 2.42rem;
  font-size: 1rem;
  padding: .3rem .6rem;
  border-radius: .5rem;
  border: .8px solid #1547d2;
  background-color: #eff7f5;
}

.addWishBtn {
  font-size: 1.2rem;
  font-weight: 600;
  padding: .8rem 0;
  color: #fbfdfd;
  background-color: #1547d2;
  border: transparent;
  border-radius: .6rem;
  cursor: pointer;
  width: 100%;
}

.UploadAnImage {
  margin-top: 1rem;
}

.categoriesDiv {
  display: flex;
  flex-direction: column;
  margin: 3rem auto 1.8rem;
  gap: .6rem;
}

.categoriesDiv div {
  display: flex;
  justify-content: space-between;
  gap: .5rem;
  margin: 1rem 0;
}

.categoriesDiv input {
  height: 2rem;
  font-size: 1rem;
  padding: .3rem .6rem;
  border-radius: .5rem;
  border: .8px solid #1547d2;
  background-color: #eff7f5;
  width: 100%;
}

.categoryBtn {
  width: 20%;
  border: 1.5px solid #1547d2;
  background-color: #eff7f5;
  border-radius: .3rem;
  font-size: 1.2rem;
  color: #1547d2;
}

.wishInfoTitle {
  font-size: 1.3rem;
  font-family: 'Archivo', sans-serif;
  margin: 1rem 0;
  color: #d4145a;
}

.wishImag {
  width: 100%;
  height: 210px;
  object-fit: cover;
  border-radius: .42rem;
}

.wishPrice {
  margin: .6rem 0;
}

.theWishDiv {
  background-color: #f0f9f9;
  padding: .5rem;
  border-radius: .8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.addToCartBtn {
  font-size: 1.2rem;
  font-weight: 500;
  padding: .5rem 0;
  color: #1547d2;
  background-color: transparent;
  border: 1px solid #1547d2;
  border-radius: .3rem;
  cursor: pointer;
  width: 100%;
}

.addToCartBtnIcon {
  font-size: 1rem;
  margin-right: .5rem;
}

.wishDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  margin: 1.5rem 0 .5rem;
}

.wishOptionBtn {
  font-size: 1rem;
  cursor: pointer;
}

.editingDiv {
  background-color: #c7ecee;
  padding: .8rem .5rem 0;
  border-radius: .6rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border: .8px solid #535353;
  position: absolute;
  bottom: -2rem;
  right: 15%;
}

.editingDiv p {
  display: flex;
  align-items: flex-start;
  gap: .6rem;
  font-size: 1.2rem;
  margin: .6rem 0;
  cursor: pointer;
}

.closeEditDiv {
  position: absolute;
  top: .18rem;
  right: .2rem;
  cursor: pointer;
}

.relativeWrapper {
  position: relative;
}

/* [[[--------------------------------------------------------------------]]] */
._popUpWishName {
  font-size: 1.1rem;
  font-weight: 500;
  color: #464545;
}

._popUpPrice {
  font-size: 1rem;
  letter-spacing: .6px;
  font-weight: 500;
  color: rgb(11, 11, 11);
  font-family: sans-serif;
  margin: .8rem 0;
}

._popUpCloseIcon {
  position: absolute;
  top: 1.5rem;
  right: 1.6rem;
  transform: translate(50%, -50%);
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.3rem;
}

._popUpImg {
  width: 235px;
  height: 250px;
  object-fit: cover;
  border-radius: .8rem;
  margin-bottom: 1rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

._price_caution {
  font-size: .88rem;
  color: #d4145a;
  margin: .3rem 0;
}

._add_img {
  width: 12rem;
}

._add_wish_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  margin: 2rem 0;
}

@media screen and (max-width: 1024px) {

  .profileLinks {
    width: 95%;
  }

  .userBio {
    width: 90%;
  }

  .wishDetails {
    margin: 1.5rem .3rem 0;
  }

  .wishImag {
    width: 100%;
    border-radius: 0;
    border-top-right-radius: .6rem;
    border-top-left-radius: .6rem;
  }

  .addToCartBtn {
    font-size: 1rem;
    padding: .5rem;
    width: 95%;
    margin: .5rem .3rem;
  }

  .profileEdit,
  .editIconsBtn {
    font-size: 1.2rem;
    border-radius: .5rem;
    gap: .5rem;
  }

  .EditIconsDiv {
    align-items: flex-start;
    margin: 0.3rem 0.8rem;
    display: flex;
    flex-direction: column;
  }

  .userSocialLinks {
    width: 80%;
    margin: 1rem;
  }

  .userNameDiv {
    width: 80%;
  }

  @media screen and (max-width: 900px) {
    .userCover {
      height: 10rem;
    }

    .wishImag {
      width: 100%;
      max-height: 200px;
      object-fit: cover;
      border-radius: 0;
      border-top-right-radius: .6rem;
      border-top-left-radius: .6rem;
    }

    .userSocialLinks {
      width: 100%;
      margin: 0;
    }

    .profileLinks {
      width: 90%;
      gap: 1rem;
    }

    .theWishesSection {
      margin-top: 2rem;
    }

    .profileEdit,
    .editIconsBtn {
      font-size: 1rem;
      padding: .3rem .3rem;
      border-radius: .5rem;
      gap: .2rem;
    }

    .EditIconsDiv {
      width: 100%;
      margin: .8rem 0;
    }

    .userBio {
      width: 90%;
    }

    .userNameDiv {
      width: 90%;
    }

    @media screen and (max-width: 820px) {
      .theWishDiv {
        padding: 0;
        border-radius: .4rem;
      }

      .wishImag {
        border-top-right-radius: .4rem;
        border-top-left-radius: .4rem;
      }

      ._add_wish_container h3 {
        font-size: 1.1rem;
      }

      @media screen and (max-width: 600px) {
        .wishUploaderSection {
          width: 300px;
          margin: 0rem auto;
          height: 40rem;
          position: fixed;
          top: 50%;
          padding: 2rem 1.2rem;
          border-radius: 1.4rem;
        }

        .wishUploadImg {
          max-width: 150px;
          min-height: 100px;
        }

        .addWishBtn {
          font-size: 1rem;
        }

        .profileLinks {
          width: 45%;
        }

        .userCover {
          height: 8rem;
        }

        .editingDiv p {
          gap: .6rem;
          font-size: .9rem;
          margin: .6rem 0;
        }

        ._price_caution {
          font-size: .8rem;
        }

        ._add_img {
          width: 6.8rem;
        }

        ._add_wish_container h3 {
          font-size: 1rem;
        }

        @media screen and (max-width: 374px) {
          .addToCartBtn {
            width: 93%;
          }
        }
      }
    }
  }
}