._categories_container,
._filters_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: .62rem;
  position: absolute;
  left: 0;
  top: 3.2rem;
  background-color: #fbfdfd;
  padding: 1rem 2rem;
  border-radius: .5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

._filters_container {
  left: 5rem;
  width: 15rem;
}

._category_label,
._filters_labels {
  font-size: 1.1rem;
  font-weight: 400;
  color: #636363;
  display: flex;
  gap: .3rem;
}

._categories_container ._category_label input {
  height: 1rem;
  width: 1.2rem;
  cursor: pointer;
}